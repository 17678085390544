.cdk-overlay-pane.grid-dialog-container.mat-mdc-dialog-panel {
    width: 80dvw;
    max-width: 80dvw;
    height: 85dvh;
    max-height: 85dvh;
}

.dialog-container .mat-mdc-dialog-container {
    padding: 0 0 10px 0;
    overflow: hidden;

    .mat-mdc-dialog-title+.mat-mdc-dialog-content {
        padding-top: 10px;
    }

    .mat-mdc-dialog-title {
        display: flex;
        padding-top: 12px;
        background-color: var(--color-primary);
        color: var(--color-light-text);
    }

    .mat-mdc-dialog-content {
        margin: 0;
        padding: 12px;
        max-height: 90dvh;
    }

    .mat-mdc-dialog-actions {
        padding: 12px;
        background-color: var(--color-background-secondary);
    }

    .mat-icon {
        cursor: pointer;
    }
}

.dialog-container-no-padding .mat-mdc-dialog-container {
    padding: 0;
}

.dialog-bottom-section {
    padding: 12px;
    background-color: var(--color-background-secondary);
}

.side-menu-dialog .mat-mdc-dialog-container {
    padding: 0 0 0 0;
    overflow: hidden;

    .mat-mdc-list-base {
        --mdc-list-list-item-label-text-color: var(--color-light-text);
        --mdc-list-list-item-hover-label-text-color: var(--color-list-item-focus);
        --mdc-list-list-item-focus-label-text-color: var(--color-light-text);
        --mdc-list-list-item-selected-container-color: var(--color-list-item-focus);
    }

    .mat-mdc-dialog-title {
        display: flex;
        background-color: var(--color-primary);
        color: var(--color-light-text);
        padding: 12px;
        margin: 0;
    }

    .mat-mdc-dialog-content {
        margin: 0;
        padding: 12px;
    }

    .mat-mdc-dialog-actions {
        padding: 12px;
        background-color: var(--color-background-secondary);
    }

    .dialog-side-menu-container {
        display: flex !important;
        min-height: 100%;
        border-right: solid 1px var(--color-secondary);
        background-color: var(--color-primary);
        overflow-y: auto;
        padding: 0 0 5px 0;
    
        .mat-mdc-list-base .mat-mdc-list-option {
            color: var(--color-light-text) !important;
            text-transform: uppercase;
        }

        .mat-mdc-list-base .mat-mdc-list-item-disabled {
            background-color: transparent;
            color: var(--color-light-text) !important;
            text-transform: uppercase;
            opacity: 0.3;
        }
    
        .mat-mdc-list-base .mdc-list-item .mdc-list-item-content-reverse, 
        .mat-mdc-list-base .mat-mdc-list-option .mdc-list-item-content-reverse {
            padding: 0 0 0 5px;
        }
    
        h3 {
            font-size: 12px !important;
        }

        .mat-mdc-list-option[aria-selected="true"] {
            border-left: solid 5px var(--color-list-item-focus);
        }
    }

    .sm-content-container {
        min-height: 40dvh;

        h3 {
            padding: 0 0 18px 0;
        }

        .permission-item {
            padding: 12px;

            .mat-icon {
                vertical-align: middle;
            }
        }
    }
}

.mor-dialog-label {
    font-size: 14px;
    font-weight: bold;
}