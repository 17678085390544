/*
Template Name: Material Pro Admin
Author: Wrappixel
Email: niravjoshi87@gmail.com
File: scss
*/
/**
 * Table Of Content
 *
 * 	1. Globals
 *	2. Headers
 *	3. Navigations
 *	4. Banners
 *	5. Footers
 *	6. Posts
 *	7. Widgets
 *	8. Custom Templates
 */
/******************* 
Global Styles 
*******************/

@use '@angular/material' as mat;

* {
  outline: none;
  box-sizing: border-box;
}

.app-wrapper {
  width: 100%;
  max-height: 100dvh;
}

a:hover,
a:focus,
a {
  font-family: $headingfont !important;
  text-decoration: none;
  color: var(--color-accent);
  cursor: pointer;
}

[aria-disabled="true"] {
  opacity: 0.4;
}

.img-responsive {
  width: 100%;
  height: auto;
  display: inline-block;
}
.img-fluid {
  max-width: 100%;
  height: auto;
}
.img-rounded {
  border-radius: $radius;
}
html body .mdi:before,
html body .mdi-set {
  line-height: initial;
}
code {
  padding: 0.2rem 0.4rem;
  font-size: 90%;
  color: $danger;
  background-color: $light-danger;
  border-radius: 0.25rem;
}

.shadow-none {
  box-shadow: none !important;
}
.ml-auto {
  margin-left: auto;
}
.m-auto {
  margin: auto;
}
.mr-auto {
  margin-right: auto;
}
.rounded {
  border-radius: $radius;
}
/*******************
Headings
*******************/

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0 !important;
  font-family: $headingfont !important;
  font-weight: 700 !important;
}

h1 {
  font-size: 24px !important;
}

h2 {
  font-size: 18px !important;
}

h3 {
  font-size: 16px !important;
}

h4 {
  font-size: 14px !important;
}

h5 {
  font-size: 12px !important;
}

h6 {
  font-size: 10px !important;
}
html,
body {
  .display-5 {
    font-size: 3rem;
  }
  .display-6 {
    font-size: 36px;
  }
}
.box {
  border-radius: $radius;
  padding: 10px;
}
html body {
  .dl {
    display: inline-block;
  }
  .db {
    display: block;
  }
  .op-5 {
    opacity: 0.5;
  }
  .op-3 {
    opacity: 0.3;
  }
}
.no-wrap {
  td,
  th {
    white-space: nowrap;
  }
}
.relative {
  position: relative;
}
.absolute {
  position: absolute;
}
.d-flex {
  display: flex;
}
.no-shrink {
  flex-shrink: 0;
}
.oh {
  overflow: hidden;
}

/*******************
Paddings
*******************/
html body {
  .p-0 {
    padding: 0px;
  }

  .p-4 {
    padding: 4px;
  }

  .p-10 {
    padding: 10px;
  }

  .p-20 {
    padding: 20px;
  }

  .p-30 {
    padding: 30px;
  }

  .p-l-0 {
    padding-left: 0px;
  }

  .p-l-10 {
    padding-left: 10px;
  }

  .p-l-20 {
    padding-left: 20px;
  }

  .p-r-0 {
    padding-right: 0px;
  }

  .p-r-10 {
    padding-right: 10px;
  }

  .p-r-20 {
    padding-right: 20px;
  }

  .p-r-30 {
    padding-right: 30px;
  }

  .p-r-40 {
    padding-right: 40px;
  }

  .p-t-0 {
    padding-top: 0px;
  }

  .p-t-10 {
    padding-top: 10px;
  }

  .p-t-20 {
    padding-top: 20px;
  }

  .p-t-30 {
    padding-top: 30px;
  }

  .p-b-0 {
    padding-bottom: 0px;
  }

  .p-b-5 {
    padding-bottom: 5px;
  }

  .p-b-10 {
    padding-bottom: 10px;
  }

  .p-b-20 {
    padding-bottom: 20px;
  }

  .p-b-30 {
    padding-bottom: 30px;
  }

  .p-b-40 {
    padding-bottom: 40px;
  }
}

/*******************
Margin
*******************/
html body {
  .m-0 {
    margin: 0px;
  }

  .m-l-5 {
    margin-left: 5px;
  }

  .m-l-10 {
    margin-left: 10px;
  }

  .m-l-15 {
    margin-left: 15px;
  }

  .m-l-20 {
    margin-left: 20px;
  }

  .m-l-30 {
    margin-left: 30px;
  }

  .m-l-40 {
    margin-left: 40px;
  }

  .m-r-5 {
    margin-right: 5px;
  }

  .m-r-10 {
    margin-right: 10px;
  }

  .m-r-15 {
    margin-right: 15px;
  }

  .m-r-20 {
    margin-right: 20px;
  }

  .m-r-30 {
    margin-right: 30px;
  }

  .m-r-40 {
    margin-right: 40px;
  }

  .m-t-0 {
    margin-top: 0px;
  }

  .m-t-5 {
    margin-top: 5px;
  }

  .m-t-10 {
    margin-top: 10px;
  }

  .m-t-15 {
    margin-top: 15px;
  }

  .m-t-20 {
    margin-top: 20px;
  }

  .m-t-30 {
    margin-top: 30px;
  }

  .m-t-40 {
    margin-top: 40px;
  }

  .m-b-0 {
    margin-bottom: 0px;
  }

  .m-b-5 {
    margin-bottom: 5px;
  }

  .m-b-10 {
    margin-bottom: 10px;
  }

  .m-b-15 {
    margin-bottom: 15px;
  }

  .m-b-20 {
    margin-bottom: 20px;
  }

  .m-b-30 {
    margin-bottom: 30px;
  }

  .m-b-40 {
    margin-bottom: 40px;
  }
}

/*******************
vertical alignment
*******************/
html body {
  .vt {
    vertical-align: top;
  }

  .vm {
    vertical-align: middle;
  }

  .vb {
    vertical-align: bottom;
  }
}
/*******************
Opacity
*******************/
.op-5 {
  opacity: 0.5;
}
/*******************
font weight
*******************/
html body {
  .font-bold {
    font-weight: 700;
  }

  .font-normal {
    font-weight: normal;
  }

  .font-light {
    font-weight: 300;
  }

  .font-medium {
    font-weight: 500;
  }

  .font-16-n {
    font-size: 16px;
    font-weight: normal;
  }

  .font-18-n {
    font-size: 18px;
    font-weight: normal;
  }

  .font-24-n {
    font-size: 24;
    font-weight: normal;
  }
}
/*******************
Border
*******************/
html body {
  .b-0 {
    border: none;
  }
  .b-r {
    border-right: 1px solid $border;
  }
  .b-l {
    border-left: 1px solid $border;
  }
  .b-b {
    border-bottom: 1px solid $border;
  }
  .b-t {
    border-top: 1px solid $border;
  }
  .b-all {
    border: 1px solid $border;
  }
}

.btn-block {
  display: block;
  width: 100%;
}
.btn-lg {
  line-height: 48px !important;
}
/*******************
forms
*******************/
.form-control {
  border: 0px;
  line-height: 25px;
  padding: 10px;
  width: 100%;
  font-size: $font-16;
}
.mat-mdc-form-field-underline {
  background-color: rgba(0, 0, 0, 0.25);
}
.mat-mdc-form-field {
  width: 100%;
}
/*******************
Mat card
*******************/
.mat-mdc-card {
  margin: 12px;
  padding: 0px !important;
  border-radius: $radius;

  .mat-mdc-card-content {
    font-size: 0.9rem;
    line-height: 23px;
  }

  .mat-mdc-card-content:last-child {
    padding-bottom: 4px;
  }

  .mat-mdc-card-image {
    width: calc(100% + 0px);
    margin: 0 0px 0px 0px;
  }
  .mat-mdc-card-header {
    padding: 12px;
    border-radius: $radius $radius 0 0;

    .mat-mdc-card-title {
      margin-bottom: 2px;
      font-size: 20px;
    }
    .mat-mdc-card-subtitle,
    .mat-mdc-card-subtitle:not(:first-child) {
      margin-bottom: 0px;
      margin-top: 0px;
      font-size: 14px;
    }
    &.text-white {
      .mat-mdc-card-title {
        color: $white;
      }
      .mat-mdc-card-subtitle {
        color: rgba(255, 255, 255, 0.7);
      }
    }
  }
  .mat-mdc-card-title {
    font-size: 20px;
    margin-bottom: 5px;
  }
  .mat-mdc-card-subtitle {
    margin-bottom: 20px;
  }
  .mat-mdc-card-actions {
    margin: 0px !important;
  }
}

.progress-card-content {
  max-height: 385px;
  background-color: var(--color-background-light);
  padding: 0 !important;
  overflow-y: auto;

  .pau-header-row,
  .pau-header-item,
  .pap-header-row {
    background-color: var(--color-background-light) !important;
  }

  .pau-header-item,
  .pap-header-row,
  .paa-header-row,
  .pat-header-row {
    color: var(--color-primary) !important;
  }

  .pau-header-row,
  .paa-header-row,
  .pap-header-row {
    padding-bottom: 3px !important;
    
    h3 {
      font-size: 16px !important;
    }
  }

  .spinner-background {
    font-size: 14px !important;
  }

  h3 {
    font-size: 14px !important;
  }
}

/*******************
Grid
*******************/
.row {
  margin: 0 -10px;
}
/*******************
Table
*******************/
.table {
  width: 100%;
  text-align: left;
}
.table-rasponsive {
  overflow: auto;
  .mat-table {
    min-width: 280px;
  }
  .mat-header-row {
    .mat-header-cell {
      white-space: nowrap;
    }
  }
  .mat-cell {
    white-space: nowrap;
  }
}

/*******************
list inline
*******************/
.list-inline {
  margin-left: 0px;
  padding: 0px;
  li {
    display: inline-block;
    list-style: none;
    margin-right: 10px;
    &:last-child {
      margin-right: 0px;
    }
  }
}

/*******************
VC-Card
*******************/
.vc-card {
  background-color: white !important;

  .mat-mdc-card-header {
    background-color: var(--color-primary) !important;
    color: var(--color-light-text);
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 10px 6px;
    box-shadow: 0px 3px 4px rgba(0, 0, 0, 0.25), 2px 2px 3px rgba(0, 0, 0, 0.25);
  }

  .mat-mdc-card-content {
    padding: 0 6px 6px;
  }

  .title-container {
    width: 100%;
    display: flex;
    gap: 10px;
  }

  .subtitle-container {
    width: 100%;
    align-items: center;

    h3 {
      font-style: italic;
      font-weight: lighter !important;
      margin: 0;
      opacity: .7;
      text-align: center;
      align-self: flex-end;
    }

    .toggle-container {
      display: flex;
      width: 100%;
      justify-content: flex-end;
      align-self: flex-end;
    }
  }
}

/*******************
VC-Card Summary Info
*******************/
.vc-card-summary-grid {

  .mat-grid-tile {
    h2, h3, h4, p {
        margin: 0;
    }

    p {
      font-size: 16px;
      font-weight: normal;
    }
  }

  .header-tile {
    border-bottom: 0.75px solid var(--color-light-divider-line);
  }

  .expanded-tile {
    .mat-grid-tile-content {
      padding: 0 0 0 10px;
    }
    p {
        font-size: 14px !important;
    }
  }
  
  .expanded-section-header-tile {
    p {
        font-size: 14px !important;
    }
  }
}

.vc-card-charts {
  .mat-mdc-card-content {
    padding: 12px 12px 24px !important;
  }
}

/*******************
mat-mdc-form-field
*******************/
.mdc-notched-outline__notch { 
  border-right: none; 
}

.mat-mdc-form-field {
  --mdc-filled-text-field-label-text-color: var(--color-primary);
  --mat-form-field-container-height: 36px;
  --mat-form-field-container-vertical-padding: 12px;
}

.mat-mdc-form-field-appearance-outline .mat-mdc-form-field-infix {
  padding: .5em 0 !important;
}

.mat-mdc-form-field-required-marker {
  color: var(--color-primary) !important;
}

.mat-mdc-form-field-label {
  color: var(--color-field-label) !important;
}

.placeholder-input {
  margin: 0 0 5px 0 !important;
}

.form-field-less-bottom-padding {
  .mat-mdc-form-field .mat-mdc-form-field-wrapper {
    padding-bottom: 0.5em !important;
  }
}

.mat-mdc-form-field-appearance-outline .mat-mdc-form-field-prefix, 
.mat-mdc-form-field-appearance-outline .mat-mdc-form-field-suffix {
  top: 0 !important;
}

/*******************
mat-menu
*******************/
.config-menu-panel {
  background-color: var(--color-background-secondary);

  .mat-mdc-menu-item {
    background-color: var(--color-background-light);
    line-height: 0;
    height: auto;
    padding: 0px;
  }
}

.service-year-menu-panel {
  .mat-mdc-menu-item {
    line-height: 0;
    height: 32px;
  }
}

/*******************
mat-mdc-button
*******************/
.mat-mdc-unelevated-button.primary-button {
  --mdc-filled-button-container-color: var(--color-primary);
  --mdc-filled-button-label-text-color: var(--color-light-text);
  --mdc-filled-button-disabled-container-color: var(--color-button-disable);

  display: flex;
  width: 100px;
  height: 28px;
  align-items: center;
  justify-content: center;
}

.mat-mdc-unelevated-button.primary-button-longtext {
  --mdc-filled-button-container-color: var(--color-primary);
  --mdc-filled-button-label-text-color: var(--color-light-text);
  --mdc-filled-button-disabled-container-color: var(--color-button-disable);

  display: flex;
  height: 28px;
  align-items: center;
  justify-content: center;
  padding: 0px 16px;

  .mat-icon {
    margin: 0px 3px 0px 0px;
  }
}

.mat-mdc-unelevated-button.secondary-button {
  --mdc-filled-button-container-color: var(--color-default-material-comps);
  --mdc-filled-button-label-text-color: var(--color-primary);
  --mdc-filled-button-disabled-container-color: var(--color-button-disable);

  display: flex;
  width: 100px;
  height: 28px;
  align-items: center;
  justify-content: center;
}

/*******************
mat-tooltips
*******************/
.app-tooltip,
.field-tooltip,
.multi-line-tooltip {
  .mdc-tooltip__surface {
    font-size: 14px;
    font-weight: 700;
    color: var(--color-tooltip-text);
    background-color: var(--color-background-secondary);
    box-shadow: 0px 0px 0px rgba(16, 22, 26, 0.1), 0px 2px 4px rgba(16, 22, 26, 0.2), 0px 8px 24px rgba(16, 22, 26, 0.2);
    border-radius: 3px;
  }
}

.field-tooltip {
  .mdc-tooltip__surface {
    max-width: 300px !important;
    overflow-x: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}

.multi-line-tooltip {
  .mdc-tooltip__surface {
    white-space: pre;
  }
}

/*******************
mat-chips
*******************/
.mat-mdc-chip {
  font-size: 12px !important;
}

.mat-mdc-chip.mat-mdc-standard-chip {
  background-color: var(--color-default-material-comps);
  color: var(--color-dark-text);
  min-height: 25px !important;
  cursor: pointer;
}

.mat-mdc-chip.mat-mdc-standard-chip:hover {
  background-color: var(--color-active-material-comps);
  font-weight: 700;
}

.mat-mdc-chip-selected {
  background-color: var(--color-active-material-comps) !important;
  color: var(--color-dark-text) !important;
  border: solid 2px var(--color-primary) !important;
  font-weight: 700 !important;
}

/*******************
mat-icons
*******************/
.mat-mdc-icon {
  cursor: pointer;
}

/*******************
scrollbar
*******************/
/* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background-color: transparent;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: var(--color-scrollbar);
  background-clip: content-box;
  border: 2px solid transparent;
  border-radius: 10px;
}

/*******************
mat-select
*******************/
.mat-mdc-option {
  --mat-minimal-pseudo-checkbox-selected-checkmark-color: var(--color-dark-text);
}
.select-additional-selection {
  opacity: 0.75;
  font-size: 0.75em;
}

.mat-select-trigger {
  .span {
    color: var(--color-dark-text);
  }
}

/*******************
table-list
*******************/
.app-table-list {

  padding: 2px;
  border: solid 1px var(--color-default-material-comps);
  border-radius: 3px;
  background: var(--color-background-secondary);

  .header-row {
    background: var(--color-list-secondary-row);
    color: var(--color-primary) !important;
    max-height: 35px;
    min-height: 35px;
    border-bottom: solid 1px var(--color-default-material-comps);
  }

  .row-item {
    max-height: 35px;
    min-height: 35px;

    .col-border-right {
      border-right: solid 1px var(--color-default-material-comps);
    }
  }

  .row-item:hover .list-col-1,
  .row-item:hover .list-col-2,
  .row-item:hover .list-col-3 {
    background: var(--color-list-hover);
    color: (--color-primary);
  }

  .col-padding {
    padding: 0 0 0 12px;
  }

  .row-item:nth-child(odd) {
    background: var(--color-list-secondary-row);
  }

  .row-item:nth-child(even) {
    background: var(--color-background-light);
  }

  .row-item-disabled {
    pointer-events: none;
    opacity: 0.3;
  }
}

/*******************
mat-tab
*******************/
.mat-mdc-tab-nav-bar.mat-background-primary {
  --mat-tab-header-with-background-background-color: var(--color-primary);
}

.mdc-text-field--filled:not(.mdc-text-field--disabled) {
  --mdc-filled-text-field-container-color: var(--color-background-secondary);
}

.mat-mdc-tab-label {
  font-weight: 700 !important;
  text-transform: uppercase;
  color: var(--color-primary);
}

.mat-mdc-tab-label-active {
  opacity: 1 !important;
}

.mat-mdc-tab-body-content {
  display: flex;
  padding-top: 5px;
}

.mat-mdc-tab-body-wrapper {
  height: 100%;
}

.reports-mat-tab {
  .mat-mdc-tab-body-wrapper .mat-mdc-tab-body.mat-mdc-tab-body-active {
    position: absolute !important;
  }
}

/*******************
 mat-tree
*******************/
.tree-menu {
  width: 226px;

  mat-selection-list {
    width: 226px;

    .mat-tree {
      --mdc-list-list-item-label-text-color: var(--color-light-text);
      --mdc-list-list-item-hover-label-text-color: var(--color-list-item-focus);
      --mdc-list-list-item-focus-label-text-color: var(--color-light-text);
      background-color: var(--color-primary) !important;
      padding: 0;
      width: 226px
    };
  }
  
  .mat-tree-node, .mat-nested-tree-node {
    height: 48px;
    cursor: pointer;
  }

  ul, li {
    margin-top: 0;
    margin-bottom: 0;
    list-style-type: none;
  }

  .mat-nested-tree-node div[role=group] {
    padding-left: 15px;
    border-bottom: solid 2px var(--color-list-item-focus);
  }

  div[role=group] > .mat-tree-node {
    padding-left: 15px;
  }

  .mat-nested-tree-node[aria-expanded="true"] {
    .parent-node {
      border-top: solid 2px var(--color-list-item-focus);
    }
  }

  .tree-item-icon {
    width: 32px;
  }
}

/*******************
mat-slide-toggle
*******************/
.mat-mdc-slide-toggle {
  @include mat.slide-toggle-overrides((
    track-height: 14px,
    track-width: 35px,
    unselected-with-icon-handle-horizontal-margin: 0,
    unselected-handle-horizontal-margin: 0,
    unselected-pressed-handle-horizontal-margin: 0,
    selected-with-icon-handle-horizontal-margin: 0 17px,
    selected-handle-horizontal-margin: 0 17px,
    selected-pressed-handle-horizontal-margin: 0 17px,
    unselected-handle-size: 20px,
    selected-handle-size: 20px,
    with-icon-handle-size: 20px,
    pressed-handle-size: 20px,
    // state-layer-size: 20px,
    track-outline-width: 0,
  ));

  --mdc-switch-selected-handle-color: var(--color-mat-switch-selected-handle) !important;
  --mdc-switch-selected-hover-handle-color: var(--color-mat-switch-selected-handle) !important;
  --mdc-switch-selected-focus-handle-color: var(--color-mat-switch-selected-handle) !important;
  --mdc-switch-selected-pressed-handle-color: var(--color-mat-switch-selected-handle) !important;
  --mdc-switch-selected-track-color: var(--color-mat-switch-selected-track) !important;
  --mdc-switch-selected-focus-track-color: var(--color-mat-switch-selected-track) !important;
  --mdc-switch-selected-pressed-track-color: var(--color-mat-switch-selected-track) !important;
  --mdc-switch-selected-hover-track-color: var(--color-mat-switch-selected-track) !important;
  --mdc-switch-unselected-handle-color: var(--color-mat-switch-unselected-handle) !important;
  --mdc-switch-unselected-hover-handle-color: var(--color-mat-switch-unselected-handle) !important;
  --mdc-switch-unselected-focus-handle-color: var(--color-mat-switch-unselected-handle) !important;
  --mdc-switch-unselected-pressed-handle-color: var(--color-mat-switch-unselected-handle) !important;
  --mdc-switch-unselected-track-color: var(--color-mat-switch-unselected-track) !important;
  --mdc-switch-unselected-focus-track-color: var(--color-mat-switch-unselected-track) !important;
  --mdc-switch-unselected-pressed-track-color: var(--color-mat-switch-unselected-track) !important;
  --mdc-switch-unselected-hover-track-color: var(--color-mat-switch-unselected-track) !important;

  .mdc-switch__handle {
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.2); /* Add your preferred shadow */
  }
}

.vc-card-charts .mat-mdc-slide-toggle {
  --mat-switch-label-text-color: var(--color-light-text) !important;
}

.mat-mdc-paginator-page-size-select {
  --mat-form-field-container-height: 36px;
  --mat-form-field-container-vertical-padding: 4px;
}

/*******************
sidenav
*******************/

mat-list-option.mdc-list-item--selected {
  background: rgba(0,0,0,.12) !important;
  .mdc-list-item__primary-text {
    color: $white !important;
  }
}

/*******************
misc.
*******************/

.data-refresh-label {
  font-style: italic;
  padding: 15px 0 0 0;
  font-size: 12px;
}

.error-banner {
  color: var(--color-warn);
  padding: 10px;
  text-align: center;
}

.error-box {
  background-color: var(--color-warning);
  color: var(--color-warn-text);
  border: 1px solid var(--color-warn-text);
  border-radius: 3px;
  padding: 12px;
}

.service-year-menu-panel {
  .cdk-overlay-pane {
      margin-left: 85px !important;
  }
}

.report-icon {
  cursor: pointer;
}

.mat-icon {
  cursor: pointer;
}

.timeline-container button {
  box-shadow: none !important;
  border-radius: 50% !important;
}