/* You can add global styles to this file, and also import other style files */

.hide {
  display: none !important;
}

img {
  -webkit-touch-callout: none !important;
  -webkit-user-select: none !important;
}

body {
  touch-action: none;
}

.unselectable {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.color1 {
  background-color: #6d7335 !important;
}

.color2 {
  background-color: #996860 !important;
}

.color3 {
  background-color: #85adde !important;
}

.color4 {
  background-color: #2b2d2b !important;
}

.color5 {
  background-color: #0193fa !important;
}

.color6 {
  background-color: #4d6b88 !important;
}