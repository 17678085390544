/*
Template Name: Material Pro Admin
Author: Themedesigner
Email: niravjoshi87@gmail.com
File: scss
*/

// Variables

$bodyfont: "Open Sans", sans-serif;
$headingfont: "Open Sans", sans-serif;

//@import "@angular/material/theming";

:root {
    --color-primary: #{mat.get-theme-color(vc-theme.$light-theme, primary, 25)};
    --color-accent: #{mat.get-theme-color(vc-theme.$light-theme, tertiary, 50)};
    --color-warn: #{mat.get-theme-color(vc-theme.$light-theme, error, 35)};
    --color-primary-border: #{mat.get-theme-color(vc-theme.$light-theme, primary, 10)};
    --color-warn-text: #750000;
    --color-secondary: #8C9FB7;
    --color-background-light: white;
    --color-background-secondary: #E9ECF0;
    --color-light-text: white;
    --color-dark-text: black;
    --color-light-divider-line: #b1b1b1;
    --color-dark-divider-line: #e2e2e2;
    --color-scrollbar: #b3b3b3;
    --color-default-material-comps: #bec4c9;
    --color-active-material-comps: #BAC6D4;
    --color-tooltip-text: rgba(0, 0, 0, 0.6);
    --color-button-disable: #bac6d4;
    --color-button-active: #1c2c40;
    --color-button-border: #0b2547;
    --color-button-active-border: #13325a;
    --color-field-label: #424242;
    --color-list-item-focus: hsla(215, 100%, 93%, 0.6);
    --color-list-secondary-row: #F5F7F7;
    --color-list-hover: #D8E9FF;
    --color-charts-1: var(--color-primary);
    --color-charts-2: #425C8B;
    --color-charts-3: #677BA7;
    --color-charts-4: #B0BEE1;
    --color-charts-5: #A3A3A3;
    --color-charts-6: #8672b4;
    --color-charts-7: #493b6b;
    --color-ag-parent-row: #e0e0e0;

    --header-height: 106px;
    --side-menu-column-width: 110px;
    --mdc-dialog-container-shape: 3px;
    --mdc-filled-button-container-shape: 3px;
    --mat-option-selected-state-label-text-color: var(--mdc-list-list-item-label-color);
    --mat-option-selected-state-layer-color: rgba(26, 28, 31, 0.12);
    --mat-filled-button-horizontal-padding: 12px;
    --mat-sidenav-content-background-color: white;
}

/*Theme Colors (not used)*/

$topbar: #333333;
$sidebar: #fff;
$sidebar-white: #e5edef;
$sidebar-alt: #f2f6f8;
$bodycolor: #eef5f9;
$headingtext: #455a64;
$bodytext: #67757c;
$sidebar-text: #607d8b;
$sidebar-icons: #99abb4;

$font-16: 16px;

$light-text: #a6b7bf;
$themecolor: #1e88e5;
$themecolor-alt: #999999;
$themecolor-dark: #028ee1;

/*bootstrap Color*/
$danger: #fc4b6c;
$success: #26c6da;
$warning: #ffb22b;
$primary: #7460ee;
$info: #1e88e5;
$inverse: #2f3d4a;
$muted: #99abb4;
$dark: #263238;
$light: #f2f4f8;
$extra-light: #ebf3f5;
$secondary: #727b84;

/*Light colors*/
$light-danger: #f9e7eb;
$light-success: #e8fdf8;
$light-warning: #fff8ec;
$light-primary: #f1effd;
$light-info: #e3f3fd;
$light-inverse: #f6f6f6;
$light-megna: #e0f2f4;

$danger-dark: #e6294b;
$success-dark: #1eacbe;
$warning-dark: #e9ab2e;
$primary-dark: #6352ce;
$info-dark: #028ee1;
$red-dark: #d61f1f;
$inverse-dark: #232a37;
$dark-transparent: rgba(0, 0, 0, 0.05);

/*Normal Color*/
$white: #ffffff;
$red: #fb3a3a;
$yellow: #a0aec4;
$purple: #7460ee;
$blue: #02bec9;
$megna: #00897b;

/*Extra Variable*/
$rgt: right;
$lft: left;
$border: rgba(120, 130, 140, 0.13);
$table-border: #f3f1f1;
$card-brd: #d7dfe3;
$dark-text: #848a96;
$radius: 4px;
$form-brd: #d9d9d9;