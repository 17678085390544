/*
Template Name: Material Pro Admin
Author: Themedesigner
Email: niravjoshi87@gmail.com
File: scss
*/
/*============================================================== 
 For Laptop & above all (1650px) 
 ============================================================== */

@media(min-width:1650px) {
    .main-container.boxed{
        max-width: 1920px;
        margin: 0 auto;
    }
}
/*============================================================== 
 For Laptop & above all (1370px) 
 ============================================================== */

@media(max-width:1600px) {
     .main-container.boxed{
        max-width: 1300px;
        margin: 0 auto;
    }
}

/*============================================================== 
 For Laptop & all below (1370px) 
 ============================================================== */

@media(max-width:1370px) {
    h1 {
        font-size: 20px !important;
    }
      
    h2 {
        font-size: 16px !important;
    }
      
    h3 {
        font-size: 14px !important;
    }
    
    h4 {
        font-size: 12px !important;
    }
    
    h5 {
        font-size: 10px !important;
    }
    
    h6 {
        font-size: 8px !important;
    }

    .vc-card-summary-grid {
        p {
            font-size: 14px !important; 
        }
    }

    .expanded-tile {
        p {
            font-size: 12px !important;
        }
    }

    .dialog-side-menu-container {
    
        .mat-list-base .mat-list-option {
            font-size: 12px;
        }
    }

    .sm-content-container {

        .permission-item {
            font-size: 12px;
            padding: 2px !important;
        }
    }
}

@media(max-width:1100px) {
    h1 {
        font-size: 18px !important;
    }
      
    h2 {
        font-size: 14px !important;
    }
      
    h3 {
        font-size: 12px !important;
    }
    
    h4 {
        font-size: 10px !important;
    }
    
    h5 {
        font-size: 8px !important;
    }
    
    h6 {
        font-size: 6px !important;
    }

    .vc-card-summary-grid {
        p {
            font-size: 12px !important; 
        }
    }

    .expanded-tile {
        p {
            font-size: 10px !important;
        }
    }

    .progress-card-content {
        h3 {
            font-size: 12px !important;
        }
    }

    .sm-content-container {

        .permission-item {
            font-size: 12px;
            padding: 2px !important;
        }
    }
}


/*-- ============================================================== 
 Small Desktop & above all (1024px) 
 ============================================================== */

@media(min-width:1024px) {
  .card-group{
    padding: 0 15px;
    display: flex;
    flex-flow:row wrap;
    .mat-card{
       margin: 15px 0px;
       flex: 1 0 0%;
        border-left:1px solid $border;
            &:first-child{
                border:0px;
            }
        }
    }
    
    .sm-content-container {

        .permission-item {
            font-size: 12px;
            padding: 2px !important;
        }
    }
    
}

@media(max-width:1023px) {
    .card-group{
        margin: 15px 0;
        .mat-card{
            margin: 0 15px;
            border-top:1px solid $border;
            &:first-child{
                border:0px;
            }
        }
    }

    .sm-content-container {

        .permission-item {
            font-size: 12px;
            padding: 2px !important;
        }
    }
}


/*-- ============================================================== 
 Ipad & above all(768px) 
 ============================================================== */

@media(min-width:768px) {
    .navbar-header {
        width: 225px;
        flex-shrink:0;
        .navbar-brand {
            padding-top: 0px;
        }
    }
    
}


/*-- ============================================================== 
 Phone and below ipad(767px) 
 ============================================================== */

@media(max-width:767px) {
    .mat-toolbar-single-row{
        height: 64px!important;
    }
    .d-flex{
        display: block;
    }
    .no-block{
        display: flex;
    }
    .email-right-panel{
        .col-md-2{
            width: 100%;
        }
        }
    .icon-box{
        width: 50%;
    }
}