@use '@angular/material' as mat;
@use 'colors/vc-light-theme.scss' as vc-theme;

@tailwind base;
@tailwind utilities;

@include mat.core();

@import 'variable';

@import 'spinner';
@import 'app';
@import 'header';
@import 'pages';
@import 'dark-sidebar';
@import 'responsive';
@import 'rtl';
@import 'dialog';

@import 'ag-grid-community/styles/ag-grid.css';
@import 'ag-grid-community/styles/ag-theme-balham.css';
@import 'ag-grid';

html{
    @include mat.all-component-themes(vc-theme.$light-theme);
    @include mat.color-variants-backwards-compatibility(vc-theme.$light-theme);
}