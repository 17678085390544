.ag-root-wrapper {
    border-radius: 5px;
}

.ag-theme-balham {
    --ag-border-radius: 5px;
    --ag-range-selection-border-color: transparent;
}

.ag-grid-outer-container {
    width: var(--row-width);
    max-width: var(--row-width);
    height: var(--row-height);
    max-height: var(--row-height);
    padding: 5px 0;
}

.ag-chart-tooltip-title {
    background-color: var(--color-accent);
}

.ag-theme-balham .ag-header-group-cell-label {
    justify-content: center;
}

.ag-theme-balham .ag-header-group-cell {
    background-color: var(--color-charts-2);
    color: var(--color-light-text);
    --ag-header-cell-hover-background-color: var(--color-charts-2);
}

.ag-theme-balham .ag-header-cell {
    //background-color: var(--color-charts-4);
    color: var(--color-dark-text);
}

.ag-grid-header-alt {
    .ag-theme-balham {
        --ag-header-background-color: var(--color-charts-2);
    }
    
    .ag-theme-balham .ag-header-cell {
        color: var(--color-light-text);
    }
}

.allocation-grid {
    width: var(--row-width);
    height: var(--row-height);
    padding: 5px 0;

    .ag-row {
        border: none;
        margin-bottom: 0;
    }

    .ag-theme-balham .ag-row.ag-row-level-0 {
        background-color: var(--color-ag-parent-row);
        --ag-row-hover-color: var(--color-ag-parent-row);
        font-weight: bold;
    }

    .ag-theme-balham .ag-row.ag-row-level-1 {
        background-color: var(--color-background-light);
        --ag-row-hover-color: var(--color-list-hover);
    }
}

.report-grid-alt {
    .ag-theme-balham .ag-row {
        border: none;
        margin: 0;
    }

    .ag-status-bar {
        padding: 0 11px;
    }
}

.numberType,
.percent {
    text-align: right;
}

.summation {
    text-align: right;
    font-weight: bold;
}

.center-cell {
    text-align: center;
}

.grid-container {
    height: var(--grid-height);
    padding: 5px 0;
}

  /////////////////
 // Cell Styles //
/////////////////

.danger-cell {
    background-color: lightcoral;
}

.danger-cell-2 {
    border: solid 1px var(--color-warn);
}

.warn-cell {
    background-color: lightsalmon;
}

.parent-cell {
    background-color: var(--color-ag-parent-row);
}

  //////////////////////////////
 // Dx Events Summary Report //
//////////////////////////////
$dx-event-summary-border: solid 1px #b3b3b3;
$dx-event-summary-bottom-row-border: solid 2px #b3b3b3;

.ag-theme-dx-event-summary-report {
    --ag-cell-horizontal-border: none;
}

.dxsr-display-name-1 {
    border: $dx-event-summary-border;
    font-weight: bold;
}

.dxsr-display-name-2 {
    border-top: $dx-event-summary-border;
    border-left: $dx-event-summary-border;
    border-right: $dx-event-summary-border;
    font-weight: bold;
}

.dxsr-display-name-3 {
    border-bottom: $dx-event-summary-border;
    border-left: $dx-event-summary-border;
    border-right: $dx-event-summary-border;
    padding-left: 40px;
}

.dxsr-display-name-4 {
    border-left: $dx-event-summary-border;
    border-right: $dx-event-summary-border;
    padding-left: 40px;
}

.dxsr-display-name-5 {
    border-left: $dx-event-summary-border;
    border-right: $dx-event-summary-border;
    border-bottom: $dx-event-summary-bottom-row-border;
    padding-left: 40px;
}

.dxsr-values-1 {
    border-top: $dx-event-summary-border;
    border-left: $dx-event-summary-border;
    border-bottom: $dx-event-summary-border;
    font-weight: bold;
}

.dxsr-values-2 {
    border-top: $dx-event-summary-border;
    border-left: $dx-event-summary-border;
    border-right: none;
    font-weight: bold;
}

.dxsr-values-3 {
    border-bottom: $dx-event-summary-border;
    border-left: $dx-event-summary-border;
    border-right: none;
}

.dxsr-values-4 {
    border-left: $dx-event-summary-border;
    border-right: none;
}

.dxsr-values-5 {
    border-left: $dx-event-summary-border;
    border-bottom: $dx-event-summary-bottom-row-border;
    border-right: none;
}

.dxsr-percent-1 {
    border-top: $dx-event-summary-border;
    border-right: $dx-event-summary-border;
    border-bottom: $dx-event-summary-border;
    border-left: none;
    font-weight: bold;
}

.dxsr-percent-2 {
    border-top: $dx-event-summary-border;
    border-right: $dx-event-summary-border;
    border-left: none;
    font-weight: bold;
}

.dxsr-percent-3 {
    border-bottom: $dx-event-summary-border;
    border-right: $dx-event-summary-border;
    border-left: none;
}

.dxsr-percent-4 {
    border-right: $dx-event-summary-border;
    border-left: none;
}

.dxsr-percent-5 {
    border-right: $dx-event-summary-border;
    border-bottom: $dx-event-summary-bottom-row-border;
    border-left: none;
}

.cell-align-left {
    text-align: start;
}

.cell-align-right {
    text-align: end;
}

.ag-parent-cell-right-border {
    border-top: $dx-event-summary-border !important;
    border-right: $dx-event-summary-border !important;
    border-bottom: $dx-event-summary-border !important;
    border-left: none;
    font-weight: bold;
}

.ag-parent-cell-left-border {
    border-top: $dx-event-summary-border !important;
    border-left: $dx-event-summary-border !important;
    border-bottom: $dx-event-summary-border !important;
    font-weight: bold;
}

.ag-cell-left-border {
    border-left: $dx-event-summary-border !important;
    border-right: none;
}

.ag-cell-right-border {
    border-right: $dx-event-summary-border !important;
    border-left: none;
}

