/*
Template Name: Material Pro Admin
Author: Wrappixel
Email: niravjoshi87@gmail.com
File: scss
*/


/*******************
 Topbar
*******************/
mat-toolbar{
    box-shadow: 1px 0px 10px rgba(0, 0, 0, 0.5);
    max-height: 57px;
    min-height: 57px;
    
    &.topbar{
        padding: 0px 28px 0px 10px;
    }
    .navbar-header {
        text-align: center;
        .navbar-brand {
            display: flex;
            line-height: 18px;
            
            align-items: center;

            img {
                width: 256px;
                height: 50px;
            }
        }
    }
    
    .app-search{
        display: none;
        width: 100%;
        top: 0px;
        left: 0px;
        z-index: 1;
        position: absolute;
        .form-control{
            line-height: 42px;
            
        }
        .cl-srh-btn{
            position: absolute;
            top: 12px;
            right: 15px;
            font-size: 15px;
            color:$bodytext;
            cursor: pointer
        }
    }
    .profile-pic{
        width: 40px;
        height: 40px;
        border-radius: 100%;
    }
}
/*******************
 Topbar
*******************/
.topbar {
  --mat-tab-header-label-text-size: 16px;
  //--mat-tab-header-label-text-weight: 600;
  position: relative;
  /* Make sure the toolbar will stay on top of the content as it scrolls past. */
  z-index: 2;
}

.nav-bar-container {

    .link-container:nth-child(1) {
        .mat-mdc-tab-link {
            padding-left: 14px;
        }
    }

    .mat-mdc-tab-link-container {
        border-bottom-width: 0px !important;

        .mat-mdc-tab-link {
            font-size: 18px;
            font-weight: 700;
            padding: 0 40px;
            min-width: auto;
            text-transform: none;
        }
    }

    .mat-mdc-tab-label-active {
        font-size: 20px;
        opacity: 1;
    }

    .mat-mdc-ink-bar {
        margin: 0 0 5px 0;
    }

    a:hover,
    a:focus,
    a {
        color: var(--color-light-text);
    }
}

.example-sidenav-container {
  /* When the sidenav is not fixed, stretch the sidenav container to fill the available space. This
     causes `<mat-sidenav-content>` to act as our scrolling element for desktop layouts. */
  flex: 1;
}

.example-sidenav-container {
  /* When the sidenav is fixed, don't constrain the height of the sidenav container. This allows the
     `<body>` to be our scrolling element for mobile layouts. */
  flex: 1 0 auto;
}

