/*
Template Name: Material Pro Admin
Author: Wrappixel
Email: niravjoshi87@gmail.com
File: scss
*/


/*============================================================== 
 For all pages 
 ============================================================== */


/*******************
 Main container
*******************/

.main-container {
  display: flex;
  flex-direction: column;
  position: relative;
  overflow: hidden;
  width: 100dvw;
  height: 100dvh;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.mat-drawer-container{
    background-color: var(--color-background-light);
}

html .page-wrapper{
    display: flex;
    flex-direction: column;

    .page-content{
        padding: 0;
        display: flex;
        flex-direction: column;

        .mat-tab-nav-panel {
            display: flex;
            flex-direction: column;
        }
    }
}

html .login-page-wrapper {
    height: 100dvh;
}

.grid-layout {
    display: grid;
    grid-template-columns: repeat(16, 1fr);
}

/*******************
 Oppty/Dx List
*******************/
.oppty-list-page-container {
    display: grid;
    grid-template-columns: repeat(1, 60px) repeat(1, 130px) repeat(2, 120px) repeat(12, 1fr);
    max-height: calc(100dvh - var(--header-height));
}

.dx-list-page-container,
.user-page-container,
.page-container {
    display: grid;
    grid-template-columns: repeat(16, 1fr);
    max-height: calc(100dvh - var(--header-height));
}

.allocation-side-menu {
    display: flex !important;
    flex-direction: column;
    min-height: calc(100dvh - var(--header-height));
    max-height: calc(100dvh - var(--header-height));
    grid-column-start: 1;
    grid-column-end: 3;
    background-color: var(--color-primary);
    overflow-y: auto;
    padding: 0;

    .mat-mdc-list-base {
        --mdc-list-list-item-label-text-color: var(--color-light-text);
        --mdc-list-list-item-hover-label-text-color: var(--color-list-item-focus);
        --mdc-list-list-item-focus-label-text-color: var(--color-light-text);
        --mdc-list-list-item-selected-container-color: var(--color-list-item-focus);
    }

    .allocation-side-title {
        text-transform: uppercase;
    }

    .mat-mdc-list-base .mdc-list-item .mdc-list-item-content-reverse, 
    .mat-mdc-list-base .mat-mdc-list-option .mdc-list-item-content-reverse {
        padding: 0 0 0 5px;
    }
}

.allocation-side-menu-collapsed {
    display: flex !important;
    flex-direction: column;
    min-height: calc(100dvh - var(--header-height));
    max-height: calc(100dvh - var(--header-height));
    grid-column-start: 1;
    grid-column-end: 1;
    background-color: var(--color-primary-border);
    overflow-y: auto;
    padding: 0;

    .mat-mdc-list-base {
        --mdc-list-list-item-label-text-color: var(--color-light-text);
        --mdc-list-list-item-hover-label-text-color: var(--color-light-text);
        --mdc-list-list-item-focus-label-text-color: var(--color-light-text);
    }

    .mat-mdc-list-base .mdc-list-item .mdc-list-item-content-reverse, 
    .mat-mdc-list-base .mat-mdc-list-option .mdc-list-item-content-reverse .mat-list-text {
        padding: 0 !important;
    }

    .mdc-list-item.mdc-list-item--selected {
        --mdc-list-list-item-label-text-color: var(--color-light-text);
    }

    .mat-mdc-list-option[aria-selected="true"] {
        border-left: solid 5px var(--color-list-item-focus);
    }
}

.allocation-side-menu-collapsed-content-container {
    background-color: var(--color-primary);
    display: flex !important;
    flex-direction: column;
    min-height: calc(100dvh - var(--header-height));
    max-height: calc(100dvh - var(--header-height));
    grid-column-start: 2;
    grid-column-end: 5;
    overflow: auto;
    padding: 5px;

    .allocation-side-menu-collapsed-content-header {
        padding-left: 3px;
        color: var(--color-light-text);
    }
}

.allocation-side-menu-collapsed-content {
    background-color: var(--color-background-light);
    border: solid .5px var(--color-primary-border);
    border-radius: 5px;
    padding: 0;
    overflow-y: auto;

    .pau-header-row,
    .pau-header-item,
    .pap-header-row {
        background-color: var(--color-background-light) !important;
    }

    .pau-header-item,
    .pap-header-row,
    .paa-header-row,
    .pat-header-row {
        color: var(--color-primary) !important;
    }

    .spinner-background {
        font-size: 12px !important;
    }

    h3 {
        font-size: 12px !important;
    }
}

.oppty-list-container {
    grid-column-start: 3;
    grid-column-end: 17;
    padding: 0 20px 20px;
    max-height: calc(100dvh - var(--header-height));
}

.oppty-list-container-collapsed {
    grid-column-start: 5;
}

.dx-list-container,
.user-list-container,
.page-content-container {
    grid-column-start: 1;
    grid-column-end: 17;
    padding: 0 20px 20px;
    min-height: calc(100dvh - var(--header-height));
    max-height: calc(100dvh - var(--header-height));
}

/*******************
 Non Exapnd Side Menu Page
*******************/

.side-menu-page-container {
    display: grid;
    grid-template-columns: repeat(4, var(--side-menu-column-width)) repeat(12, 1fr);
    max-height: calc(100dvh - var(--header-height));

    .mat-mdc-list-base {
        --mdc-list-list-item-label-text-color: var(--color-light-text);
        --mdc-list-list-item-hover-label-text-color: var(--color-list-item-focus);
        --mdc-list-list-item-focus-label-text-color: var(--color-light-text);
    }
}

.side-menu-page-menu-container {
    display: flex !important;
    min-height: calc(100dvh - var(--header-height));
    max-height: calc(100dvh - var(--header-height));
    grid-column-start: 1;
    grid-column-end: 3;
    border-right: solid 1px var(--color-secondary);
    background-color: var(--color-primary);
    overflow-y: auto;
    padding: 0 0 5px 0;

    .side-menu-page-menu-header {
        background-color: var(--color-primary);
        color: var(--color-light-text);
        padding: 10px 0 0 0;
        position: sticky;
        top: 0;
        z-index: 3;
        border-bottom: solid 0.7px var(--color-light-text);
    }
    
    .side-menu-page-menu-title {
        padding: 0 0 0 10px;
        text-transform: uppercase;
    }

    .mat-mdc-list-base .mat-mdc-list-option {
        //color: var(--color-light-text) !important;
    }

    .mat-mdc-list-base .mdc-list-item .mdc-list-item-content-reverse, 
    .mat-mdc-list-base .mat-mdc-list-option .mdc-list-item-content-reverse {
        padding: 0 0 0 5px;
    }

    h3 {
        font-size: 12px !important;
    }

    .mat-mdc-list-option[aria-selected="true"] {
        border-left: solid 5px var(--color-list-item-focus);
    }

    .mat-mdc-list-base.mat-mdc-list-base .mdc-list-item--selected .mdc-list-item__primary-text {
        color: var(--color-list-item-focus);
    }
}

.side-menu-page-content-container {
    grid-column-start: 3;
    grid-column-end: 17;
    padding: 0 20px 20px;
    max-height: calc(100dvh - var(--header-height));
}